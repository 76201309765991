import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LeagueTypeSelector from '../components/LeagueTypeSelector';
import DraftTypeSelector from '../components/DraftTypeSelector';
import TeamSelector from '../components/TeamSelector';
import DraftPositionSelector from '../components/DraftPositionSelector';
import RosterPositionSelector from '../components/RosterPositionSelector';
import axios from 'axios';

const DraftSetup = () => {
    const [leagueType, setLeagueType] = useState(1);     // AFL Fantasy
    const [draftType, setDraftType] = useState(1);    // Snake
    const [numberOfTeams, setNumberOfTeams] = useState(10);
    const [draftPosition, setDraftPosition] = useState(1);
    const [rosterPositions, setRosterPositions] = useState({
        Defenders: 2,
        Midfielders: 4,
        Ruck: 1,
        Forwards: 3,
        Bench: 4,
    });

    const navigate = useNavigate();

    const handleStartDraft = async () => {
        const draftSettings = {
            league_type: leagueType,
            draft_type: draftType,
            number_of_teams: numberOfTeams,
            user_draft_position: draftPosition,
            defenders: rosterPositions.Defenders,
            forwards: rosterPositions.Forwards,
            midfielders: rosterPositions.Midfielders,
            rucks: rosterPositions.Ruck,
            benches: rosterPositions.Bench,
        };
        const newDraft = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/drafts/`, draftSettings);
        const newDraftId = newDraft.data.draft.id;
        console.log("newDraftId", newDraftId);
        const startDraft = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/drafts/${newDraftId}/start_draft/`, draftSettings);

        navigate(`/draft/${startDraft.data.draft_id}`, {state: { ...draftSettings }}); 
    };

    return (
        <div className="draft-setup-page min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <h1 className="text-3xl font-bold mb-8 text-gray-800">Draft Setup</h1>
            <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 space-y-4">
                <LeagueTypeSelector onChange={setLeagueType} />
                <DraftTypeSelector onChange={setDraftType} />
                <TeamSelector onChange={setNumberOfTeams} />
                <DraftPositionSelector maxPosition={numberOfTeams} onChange={setDraftPosition} />
                <RosterPositionSelector onChange={setRosterPositions} />
                <button 
                    onClick={handleStartDraft} 
                    className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                >
                    Start Draft
                </button>
            </div>
        </div>
    );
};

export default DraftSetup;
