import React from 'react';
import { useState, useEffect } from 'react';

const Loader = ({ ballCount = 5 }) => {
    const [loadedBalls, setLoadedBalls] = useState(0);

    useEffect(() => {
        if (loadedBalls < ballCount) {
            const timer = setTimeout(() => {
                setLoadedBalls(loadedBalls + 1);
            }, 200); // Adjust the delay between ball loading
            return () => clearTimeout(timer);
        }
    }, [loadedBalls, ballCount]);

    return (
        <div className="loader-bar">
            {Array.from({ length: ballCount }).map((_, index) => (
                <div
                    key={index}
                    className="ball"
                    style={{ animationDelay: `${index * 0.3}s`, opacity: index < loadedBalls ? 1 : 0 }}
                ></div>
            ))}
        </div>
    );
};

export default Loader;
