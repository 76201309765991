import React from 'react';

const PlayerCard = ({ player, pick }) => {
    return (
        <div className="relative bg-gradient-to-r from-gray-100 to-gray-200 shadow-lg rounded-lg p-4 w-full h-80 flex flex-col transition-transform transform hover:scale-105 duration-300 ease-in-out opacity-90">
            {/* Player Info */}
            <div className="flex items-center justify-center">
                {/* Player Image */}
                <img
                    src={`../assets/player/${player.photo_url}.webp`}
                    alt={player.name}
                    className="w-24 h-24 rounded-full object-cover shadow-lg border-4 border-white"
                />
            </div>
            <div className="flex items-center">
                <div className="ml-6">
                    <div className="font-bold text-2xl w-28 text-gray-800">{player.name}</div>
                    <div className="text-gray-600 text-lg mt-2">{player.primary_position.name}</div>
                    <div className="text-gray-500 text-sm mt-1">2024 Avg Score: {player.avg_score}</div>
                    {/* <div className="text-gray-500 text-sm">Tier: {player.tier}</div> */}
                </div>
            </div>
            {/* Team Logo in Top Right */}
            <img
                src={`../assets/logo/${player.team.id}.png`}
                alt={player.team.name}
                className="absolute top-2 right-2 w-10 h-10 object-contain"
            />

            {/* Fixed Draft Button at the Bottom */}
            <div className="absolute bottom-4 left-0 right-10 flex justify-end">
                <button
                    onClick={() => pick(player)}
                    className="bg-blue-600 text-white font-bold px-6 py-2 rounded-full shadow-lg hover:bg-blue-700 hover:shadow-xl transition duration-300"
                >
                    Draft
                </button>
            </div>
        </div>
    );
};

export default PlayerCard;
