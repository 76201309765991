import React from 'react';

const PlayerSearchCard = ({ player, pick, resetSearch }) => {
    const handleDraft = () => {
        pick(player);
        resetSearch(); // Call resetSearch when the button is clicked
    };

    return (
        <div className="relative bg-gradient-to-r from-gray-100 to-gray-200 shadow-lg rounded-lg p-4 w-full h-40 flex flex-col justify-between transition-transform opacity-90">
            {/* Team Logo */}
            <img
                src={`../assets/logo/${player.team.id}.png`}
                alt={player.team.name}
                className="absolute top-2 right-2 w-12 h-12 object-contain"
            />
            
            {/* Player Info */}
            <div className="flex items-center mb-4">
                {/* Player Image */}
                <img
                    src={`../assets/player/${player.photo_url}.webp`}
                    alt={player.name}
                    className="w-16 h-16 rounded-full object-cover shadow-lg border-4 border-white"
                />
                <div className="ml-4">
                    <div className="font-bold text-lg text-gray-800">{player.name}</div>
                    <div className="text-gray-600 text-sm">{player.primary_position.name}</div>
                    <div className="text-gray-500 text-xs mt-1">2024 Avg Score: {player.avg_score}</div>
                </div>
            </div>

            {/* Draft Button */}
            <button
                onClick={handleDraft}
                className="bg-blue-600 text-white font-bold px-4 py-1 rounded-full shadow-lg hover:bg-blue-700 hover:shadow-xl transition duration-300"
            >
                Draft
            </button>
        </div>
    );
};

export default PlayerSearchCard;
