import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import PlayerCard from '../components/PlayerCard';
import PlayerSearchCard from '../components/PlayerSearchCard';
import Loader from '../components/Loader';
import Swal from 'sweetalert2';

const Draft = () => {
    let { draftId } = useParams();
    const location = useLocation();
    const [draft, setDraft] = useState([]);
    const [topPlayers, setTopPlayers] = useState(null);
    const [selectedTopPlayers, setSelectedTopPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFinished, setIsFinished] = useState(false);
    const [category, setCategory] = useState('All');
    const navigate = useNavigate();
    const [state, setState] = useState(location.state);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const searchRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchPlayers, setSearchPlayers] = useState([]);

    const handleSearchClick = () => {
        setIsSearchVisible(true);
    };

    const getDraft = async () => {
        try {
            const draft_resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/get_draft`);
            const newDraft = draft_resp.data.draft;
            newDraft.sort((a, b) => (a.round_number === b.round_number ? a.id - b.id : a.round_number - b.round_number));

            const draft_type = location.state.draft_type;
            const number_of_teams = location.state.number_of_teams;

            const groupedByRound = [];
            let currentRound = null;
            let currentGroup = [];

            newDraft.forEach(pick => {
                if (pick.round_number !== currentRound) {
                    if (currentGroup.length > 0) {
                        groupedByRound.push(currentGroup);
                    }
                    currentRound = pick.round_number;
                    currentGroup = [pick];
                } else {
                    currentGroup.push(pick);
                }
            });

            if (currentGroup.length > 0) {
                groupedByRound.push(currentGroup);
            }

            if (draft_type === 1) {
                setDraft(groupedByRound.map((group, index) => {
                    let newGroup = group;
                    if (newGroup.length !== number_of_teams) {
                        newGroup = newGroup.concat(Array(number_of_teams - newGroup.length).fill(''));
                    }
                    if (index % 2) {
                        newGroup = newGroup.reverse();
                    }
                    return newGroup;
                }));
            } else {
                setDraft(groupedByRound);
            }

            if (draft_resp.data.current_round > draft_resp.data.total_rounds) {
                Swal.fire({
                    title: 'Draft Finished!',
                    text: 'The draft has been completed successfully.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    timer: 3000
                });
                setIsFinished(true);
            }

            const top_players_resp = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/get_top_available_players`);
            setTopPlayers(top_players_resp.data);
        } catch (error) {
            console.error("Error fetching draft data", error);
        }
    };
    // Hide search results when clicking outside of the search bar and results
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsSearchVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchRef]);

    useEffect(() => {
        const loadDraft = async () => {
            await getDraft();
            setTimeout(() => setLoading(false), 1000);
        };

        loadDraft();
    }, [draftId]);

    useEffect(() => {
        if(topPlayers)
        if(category === 'All') {
            setSelectedTopPlayers(topPlayers["Defender"].slice(0,3).concat(topPlayers["Midfielder"].slice(0,3),topPlayers["Forward"].slice(0,3),topPlayers["Ruck"].slice(0,1)))
        } else {
            setSelectedTopPlayers(topPlayers[category]);
        }
    }, [category, topPlayers]);

    const handlePick = async (player) => {
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/make_pick/`, {
                player_id: player.id,
                team_position: location.state.user_draft_position,
            });
            const positions = {
                'Defender': 'defenders',
                'Forward': 'forwards',
                'Midfielder': 'midfielders',
                'Ruck': 'rucks',
            }
            if(state[positions[player.primary_position.name]] > 0)
            setState((prevState) => ({
                ...prevState,
                [positions[player.primary_position.name]]: prevState[positions[player.primary_position.name]] - 1,
            }))
            else 
            setState((prevState) => ({
                ...prevState,
                benches: prevState['benches'] - 1,
            }))
            await getDraft();
        } catch (error) {
            console.log(error)
            Swal.fire({
                title: error.response.data.error,
                text: 'The draft has been completed successfully.',
                icon: 'success',
                confirmButtonText: 'OK',
                timer: 3000
            });
        } finally {
            setTimeout(() => setLoading(false), 1000);
        }
    };

    const resetSearch = () => {
        setSearchTerm('');
        setSearchPlayers([]); // Optionally clear the search results
    };

    const handleSearchTerm = async (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/search_players/`, {
                    params: { searchTerm: e.target.value },
                });
                const filteredPlayers = response.data.players.filter(player => !draft.flat().some(pick => pick.id === player.id));
                setSelectedTopPlayers(filteredPlayers);
            } catch (error) {
                console.error("Error searching players", error);
            }
        }
    };
    

    useEffect(() => {
        if(searchTerm) {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/drafts/${draftId}/search_players?searchTerm=${searchTerm}`).then(response => {
                console.log("response", response.data);
                setSearchPlayers(response.data);
            }).catch(error => [
                console.log(error)
            ]);
        }
    }, [searchTerm])

    return (
        <div className="relative">
            <div className="relative z-10">
                {loading ? (
                    <div className="loader-container">
                        <Loader />
                    </div>
                ) : (
                    <div>
                        { isFinished ?
                        <div className='w-full flex justify-end'>
                            <button
                                onClick={() => navigate('/')}
                                className="bg-blue-600 text-white font-bold px-6 py-2 rounded-full shadow-lg hover:bg-blue-700 hover:shadow-xl transition duration-300"
                            >
                                New Draft
                            </button>
                        </div>
                        :
                        <>
                        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mt-8">
                            <ul className="flex flex-wrap justify-center text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                                {['All', 'Defender', 'Midfielder', 'Forward', 'Ruck'].map((cat) => (
                                    <li key={cat} className="me-2">
                                        <a 
                                            className={`inline-block px-4 py-3 rounded-lg transition duration-200 ${category === cat 
                                                ? 'text-white bg-blue-600 active' 
                                                : 'hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white'}`}
                                            onClick={() => setCategory(cat)}
                                        >
                                            {cat}
                                        </a>
                                    </li>
                                ))}
                                <li className="relative"  ref={searchRef}>
                                    <input 
                                        type="text" 
                                        value={searchTerm} 
                                        onChange={handleSearchTerm}
                                        onClick={handleSearchClick}
                                        className="px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-shadow duration-200 w-96"  // Increased width
                                        placeholder="Search players..."
                                    />
                                    {isSearchVisible && searchPlayers && searchTerm && (
                                        <ul className="absolute top-full left-0 w-96 mt-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg z-50 max-h-96 overflow-y-auto">
                                            {searchPlayers.map((searchPlayer) => (
                                                <li key={searchPlayer.id} className="m-2">
                                                    <PlayerSearchCard player={searchPlayer} pick={handlePick} resetSearch={resetSearch} />
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        </div>


                        <div className='flex'>
                        <div className="flex-auto grid grid-cols-1 md:grid-cols-5 grid-rows-2 gap-4 p-4">
                            {selectedTopPlayers.map((selectedTopPlayer) => (
                                    <div key={selectedTopPlayer.id} className="m-4 fade-in">
                                        <PlayerCard player={selectedTopPlayer} pick={handlePick} />
                                    </div>
                                )
                            )}
                            
                        </div>
                        <div className="p-4">
                            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                                <div className="p-4 bg-blue-100 rounded-lg shadow-md flex items-center">
                                    <img src="/ball.png" alt="Defender" className="w-8 h-8 mr-4"/>
                                    <div>
                                        <p className="text-lg font-semibold">Defender</p>
                                        <p className="text-2xl font-bold">{state.defenders}</p>
                                    </div>
                                </div>
                                <div className="p-4 bg-green-100 rounded-lg shadow-md flex items-center">
                                    <img src="/ball.png" alt="Forward" className="w-8 h-8 mr-4"/>
                                    <div>
                                        <p className="text-lg font-semibold">Forward</p>
                                        <p className="text-2xl font-bold">{state.forwards}</p>
                                    </div>
                                </div>
                                <div className="p-4 bg-yellow-100 rounded-lg shadow-md flex items-center">
                                    <img src="/ball.png" alt="Midfielder" className="w-8 h-8 mr-4"/>
                                    <div>
                                        <p className="text-lg font-semibold">Midfielder</p>
                                        <p className="text-2xl font-bold">{state.midfielders}</p>
                                    </div>
                                </div>
                                <div className="p-4 bg-purple-100 rounded-lg shadow-md flex items-center">
                                    <img src="/ball.png" alt="Ruck" className="w-8 h-8 mr-4"/>
                                    <div>
                                        <p className="text-lg font-semibold">Ruck</p>
                                        <p className="text-2xl font-bold">{state.rucks}</p>
                                    </div>
                                </div>
                                <div className="p-4 bg-red-100 rounded-lg shadow-md flex items-center">
                                    <img src="/ball.png" alt="Bench" className="w-8 h-8 mr-4"/>
                                    <div>
                                        <p className="text-lg font-semibold">Bench</p>
                                        <p className="text-2xl font-bold">{state.benches}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </div>
                        </>
                        }
                        <table className="w-full bg-white shadow-lg rounded-lg overflow-hidden mt-16 border-none opacity-80">
                            <thead>
                                <tr className="bg-blue-600 text-white border-none">
                                    {Array(location.state.number_of_teams).fill('').map((_, index) => (
                                        <th
                                            key={index}
                                            className={`px-4 py-2 text-center border-none ${location.state.user_draft_position - 1 === index ? 'bg-green-600' : 'bg-blue-600'} text-white`}
                                        >
                                            {location.state.user_draft_position - 1 === index ? 'Your team' : `AI ${index + 1}`}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {draft.length > 0 && draft.map((dr, index) => (
                                    <tr key={index} className={`border-none ${index % 2 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100`}>
                                        {dr.map((dh, i) => (
                                            <td key={i} className="w-32 h-40 text-center align-top border-none">
                                                {dh ? (
                                                    <div className={`flex flex-col items-center p-2 rounded-lg shadow-sm h-full ${location.state.user_draft_position - 1 === i ? 'bg-green-100' : 'bg-gray-200'}`}>
                                                        <img src={`../assets/player/${dh.player_photo_url}.webp`} alt={dh.player_name} className="w-16 h-16 rounded-full object-cover mb-2" />
                                                        <img src={`../assets/logo/${dh.team_name}.png`} alt={dh.team_name} className="w-10 h-10 object-cover mb-2" />
                                                        <div className="font-semibold text-lg">{dh.player_name}</div>
                                                        <div className="text-sm text-gray-700">{dh.primary_position_name}</div>
                                                        {/* <div className="text-xs text-gray-500 mt-2">Tier: {dh.player_tier}</div> */}
                                                    </div>
                                                ) : (
                                                    <div className="text-gray-400 h-full flex items-center justify-center">Empty</div>
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Draft;
