import React from 'react';
import DraftSummary from '../components/DraftSummary';

const Summary = ({ location }) => {
    const { teams } = location.state;

    return (
        <div className="summary-page">
            <DraftSummary teams={teams} />
        </div>
    );
};

export default Summary;
