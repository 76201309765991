import React, { useState } from 'react';

const DraftTypeSelector = ({ onChange }) => {
    const [selectedDraftType, setSelectedDraftType] = useState('Snake');

    const handleDraftTypeChange = (event) => {
        setSelectedDraftType(event.target.value);
        onChange(event.target.value);
    };

    return (
        <div className="draft-type-selector mb-4">
            <label htmlFor="draftType" className="block text-sm font-medium text-gray-700 mb-1">
                Draft Type:
            </label>
            <select
                id="draftType"
                value={selectedDraftType}
                onChange={handleDraftTypeChange}
                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
                <option value={1}>Snake</option>
                <option value={2}>Linear</option>
            </select>
        </div>
    );
};

export default DraftTypeSelector;
