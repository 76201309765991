import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import DraftSetup from './pages/DraftSetup';
import Draft from './pages/Draft';
import Summary from './pages/Summary';
import './index.css';


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/draft-setup" element={<DraftSetup />} />
                <Route path="/draft/:draftId" element={<Draft />} />
                <Route path="/summary" element={<Summary />} />
            </Routes>
        </Router>
    );
}

export default App;
