import React, { useState } from 'react';

const LeagueTypeSelector = ({ onChange }) => {
    const [selectedLeagueType, setSelectedLeagueType] = useState('AFL Fantasy');

    const handleLeagueTypeChange = (event) => {
        setSelectedLeagueType(event.target.value);
        onChange(event.target.value);
    };

    return (
        <div className="league-type-selector mb-4">
            <label htmlFor="leagueType" className="block text-sm font-medium text-gray-700 mb-1">
                League Type:
            </label>
            <select
                id="leagueType"
                value={selectedLeagueType}
                onChange={handleLeagueTypeChange}
                className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
                <option value="AFL Fantasy">AFL Fantasy</option>
                <option value="Keeper League" disabled>Keeper League (Coming Soon)</option>
                <option value="Supercoach" disabled>Supercoach (Coming Soon)</option>
            </select>
        </div>
    );
};

export default LeagueTypeSelector;
