import React from 'react';

const DraftSummary = ({ teams }) => {
    return (
        <div className="draft-summary">
            <h2>Draft Summary</h2>
            {teams.map((team, index) => (
                <div key={index}>
                    <h3>{team.name}</h3>
                    <ul>
                        {team.draftPicks.map((pick, pickIndex) => (
                            <li key={pickIndex}>
                                Round {pickIndex + 1}: {pick.player.name} ({pick.player.position})
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
            <button onClick={() => window.print()}>Print Summary</button>
        </div>
    );
};

export default DraftSummary;
